<template>
  <div class="row mt-4 upload-videooz align-items-center">
    <div class="col-12 mb-4">
      <h4 class="mb-1">{{ title }}</h4>
      <p>
        {{ $t("pdfOfBookDescription") }}
      </p>
    </div>
    <div
      v-if="file !== '' && file !== pdfSrc"
      class="col-12 d-flex align-items-center changed-video-image"
    >
      <div class="col-2">
        <iframe autoplay loop :src="file" width="100%"></iframe>
      </div>
      <div style="font-size: 13px" class="col-10 col-md-8 col-lg-8">
        <div>{{ $t("productEditted") }}</div>
        <div>{{ $t("undoProductEditted") }}</div>
      </div>
      <div class="col-12 col-md-2 mt-2 mt-md-0">
        <button
          @click="SET_TO_RESET_FILE()"
          class="btn w-100 btn-info btn-pill"
        >
          {{ $t("undo") }}
        </button>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <iframe
        height="400"
        controls="true"
        :src="pdfSrc"
        v-if="pdfSrc !== ''"
        class="w-100"
      ></iframe>
      <div v-else class="video-file">
        <h3>{{ $t("displayBooksPdf") }}</h3>
        <span> {{ $t("pleaseSelectPdf") }}</span>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="video-file">
        <i class="icon-graphics_05"></i>
        <h3>{{ $t("selectBooksPdf") }}</h3>
        <span>{{ $t("selectUsersPdf") }}</span>
        <p :class="`text-${alertBox.variant}`" v-if="alertBox.status">
          {{ alertBox.message }}
        </p>
        <form>
          <label for="file-pdf-upload" class="custom-file-upload">
            {{ $t("pleaseSelectPdf") }}
          </label>
          <input
            @change="SET_PDF"
            id="file-pdf-upload"
            accept="application/pdf"
            type="file"
          />
        </form>
      </div>
      <!--video-file end-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      default: "",
    },
    title: {
      type: String,
      default: function () {
        return this.$t("pdfOfBook");
      },
    },
  },
  data() {
    return {
      pdfSrc: "",
      alertBox: {
        status: false,
        message: "",
        variant: "danger",
      },
    };
  },
  methods: {
    SET_PDF(e) {
      this.alertBox.status = false;
      const vm = this;
      const file = e.target.files[0];
      if (file.type.indexOf("application/") === -1) {
        alert("Please select a pdf file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onprogress = (event) => {
          console.log(event);
        };
        reader.onload = (event) => {
          fetch(event.target.result)
            .then((res) => res.blob())
            .then((res) => {
              const url = URL.createObjectURL(res);
              vm.pdfSrc = url;
              vm.$emit("change:file", { uri: url, file });
            });
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    SET_TO_RESET_FILE() {
      this.pdfSrc = this.file;
      this.$emit("change:file", { uri: "", file: null });
    },
  },
  mounted() {
    this.pdfSrc = this.file;
  },
};
</script>

<style></style>
