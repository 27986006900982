<template>
  <div
    id="app-modal"
    class="app-modal"
    @click="SET_TO_CONTROL_USER_CLICK"
    v-if="value"
  >
    <transition
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
      mode="out-in"
    >
      <div id="app-modal-content" :class="{ sm, lg }" class="a-modal-content">
        <div class="a-modal-header" v-if="!hideHeader">
          <h5>
            <strong>{{ title }}</strong>
          </h5>
          <i @click="$emit('input', false)" class="icon-cancel"></i>
        </div>
        <div id="a-modal-body" class="a-modal-body">
          <slot></slot>
        </div>

        <div class="a-modal-footer" v-if="!hideFooter">
          <button @click="$emit('input', false)" class="btn btn-pill submit">
            {{ $t("close") }}
          </button>
          <button
            :disabled="isLoading"
            @click="CLICK_EVENT"
            class="btn btn-pill donate ml-2"
          >
            <loading v-if="isLoading"></loading>
            <span v-else>{{ okText }}</span>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Loading from "../Loading.vue";
export default {
  components: { Loading },
  props: {
    value: {
      default: false,
    },
    title: {
      default: "",
    },
    sm: {
      default: false,
      type: Boolean,
    },
    lg: {
      default: false,
      type: Boolean,
    },
    hideHeader: {
      default: false,
      type: Boolean,
    },
    hideFooter: {
      default: false,
      type: Boolean,
    },
    persistent: {
      default: false,
      type: Boolean,
    },
    okText: {
      type: String,
      default: function () {
        return this.$t("save");
      },
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  watch: {
    value(data) {
      this.SET_OVERFLOW_HIDDEN_OF_BODY(!data);
    },
  },
  methods: {
    SET_TO_CONTROL_USER_CLICK(e) {
      if (this.persistent) return;
      if (e.target.id === "app-modal") this.$emit("input", false);
    },
    SET_OVERFLOW_HIDDEN_OF_BODY(add = false) {
      if (add) document.body.classList.remove("overflow-hidden");
      else document.body.classList.add("overflow-hidden");
    },
    CLICK_EVENT() {
      this.$emit("ok", this.SET_IS_LOADING);
    },
    SET_IS_LOADING(status = false) {
      this.isLoading = status;
      if (!status && document.getElementById("a-modal-body"))
        document.getElementById("a-modal-body").scrollTop = 0;
    },
  },
};
</script>

<style></style>
